import { ChangeEvent, memo, useEffect, useState, FC } from "react";
import {
  Badge,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Stack,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import { PrimaryButton } from "../../atoms/button/PrimaryButton";
import {
  EntityTypeInfo,
  PermissionInfo,
} from "../../../types/api/tenantTableTypes";
import {
  SecurityLevelType,
  PermissionType,
  SecurityLevel_V1Type,
  PermissionPtn,
  SecurityLevelPtn,
} from "../../../types/api/commonTypes";
import { CloseIcon } from "@chakra-ui/icons";
import { GroupInfo } from "@regional-data-interconnection/common";
import { EntityTypePtn } from "../../../types/api/parameterTypes";
import { useLoginUser } from "../../../hooks/useLoginUser";

type Props = {
  entityType: EntityTypeInfo;
  isOpen: boolean;
  onInsert: (
    typeName: string,
    securityLevel: SecurityLevel_V1Type,
    permissions: PermissionInfo[],
    entityIds: string[] | undefined
  ) => void;
  onUpdate: (
    typeName: string,
    securityLevel: SecurityLevel_V1Type,
    permissions: PermissionInfo[],
    entityIds: string[] | undefined
  ) => void;
  onDelete: (typeName: string) => void;
  onClose: () => void;
  tenantGroups: GroupInfo[];
  typePtns: Array<EntityTypePtn>;
};

export const EntityTypeDetailModal: FC<Props> = memo((props) => {
  const { isOpen, onClose, entityType, tenantGroups, typePtns } = props;
  const isAddMode: boolean = entityType.TypeName ? false : true;
  const [isProvider, setIsProvider] = useState<boolean>(false);
  const [selectedTypePtn, setSelectedTypePtn] = useState<
    EntityTypePtn | undefined
  >(undefined);
  const [securityLevel, setSecurityLevel] = useState<SecurityLevel_V1Type>(
    "public"
  );
  const [permissions, setPermissions] = useState<PermissionInfo[]>([]);
  const [entityId, setEntityId] = useState(""); // 編集中のlfourID
  const [entityIds, setEntityIds] = useState<string[]>([]);

  const { selectTenant } = useLoginUser();

  useEffect(() => {
    onModalReset();
  }, [entityType]);

  useEffect(() => {
    if (selectedTypePtn) {
      selectedTypePtn.provider ? setIsProvider(true) : setIsProvider(false);
    }
  }, [selectedTypePtn]);

  const onChangeTypeName = (e: ChangeEvent<HTMLSelectElement>) => {
    const typePtn = typePtns.find(
      (typePtn) => typePtn.TypeName === e.target.value
    );
    if (typePtn) {
      setSelectedTypePtn(typePtn);
    }
  };
  const onChangeSecurityLevel = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === SecurityLevelPtn.Public) {
      setSecurityLevel(SecurityLevelPtn.Public);
    } else if (e.target.value === SecurityLevelPtn.Private) {
      setSecurityLevel(SecurityLevelPtn.Private);
    } else {
      setSecurityLevel("");
    }
  };
  const onClickUpdate = () => {
    if (selectedTypePtn) {
      if (selectedTypePtn.provider) {
        props.onUpdate(
          selectedTypePtn.TypeName,
          securityLevel,
          permissions,
          entityIds
        );
      } else {
        props.onUpdate(
          selectedTypePtn.TypeName,
          securityLevel,
          permissions,
          undefined
        );
      }
      props.onClose();
    }
  };

  const onClickCreate = () => {
    if (selectedTypePtn) {
      if (selectedTypePtn.provider) {
        props.onInsert(
          selectedTypePtn.TypeName,
          securityLevel,
          permissions,
          entityIds
        );
      } else {
        props.onInsert(
          selectedTypePtn.TypeName,
          securityLevel,
          permissions,
          undefined
        );
      }
      props.onClose();
    }
  };

  const onClickDelete = () => {
    if (selectedTypePtn) {
      props.onDelete(selectedTypePtn.TypeName);
      props.onClose();
    }
  };
  const onChangeCell =
    (index: number, key: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const _cells = [...permissions];
      _cells[index] = { ..._cells[index], [key]: event.target.value };
      setPermissions(_cells);
    };
  const onChangeList =
    (index: number, key: string) => (event: ChangeEvent<HTMLSelectElement>) => {
      const _cells = [...permissions];
      _cells[index] = { ..._cells[index], [key]: event.target.value };
      setPermissions(_cells);
    };
  const onClickListAdd = () => {
    const empty: PermissionInfo = {
      permission: PermissionPtn.non,
      tenant_id: selectTenant ?? "",
      group_id: "",
    };
    setPermissions([...permissions, empty]);
  };

  const onClickListDelete = (index: number) => {
    const newPermissions = [...permissions];
    newPermissions.splice(index, 1);
    setPermissions(newPermissions);
  };

  const onChangeEntityId = (e: ChangeEvent<HTMLInputElement>) =>
    setEntityId(e.target.value);

  const onClickEntityIdAdd = () => {
    if (entityId === "") return;
    setEntityIds([...entityIds, entityId]);
    setEntityId("");
  };

  const onClickIdListDelete = (index: number) => {
    const newEntityIds = [...entityIds];
    newEntityIds.splice(index, 1);
    setEntityIds(newEntityIds);
  };

  const onModalReset = () => {
    if (entityType) {
      const typePtn = typePtns.find(
        (typePtn) => typePtn.TypeName === entityType.TypeName
      );
      setSelectedTypePtn(typePtn);
      setSecurityLevel(entityType.SecurityLevel);
      setPermissions(entityType.Permissions);
      if (entityType.EntityIds){
        setEntityIds(entityType.EntityIds)
      }else{
        setEntityIds([])
      }
      
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onModalReset();
        onClose();
      }}
      motionPreset="slideInBottom"
      autoFocus={false}
      size={"2xl"}
    >
      <ModalOverlay />
      <ModalContent pb={2}>
        <ModalHeader>エンティティタイプ詳細</ModalHeader>
        <ModalCloseButton />
        <ModalBody mx={{ base: 0, md: 6 }}>
          <Tabs>
            <TabList>
              <Tab>詳細</Tab>
              <Tab>権限設定</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Stack spacing={4}>
                  <FormControl>
                    <FormLabel>
                      エンティティタイプ
                      {isAddMode ? (
                        <Badge colorScheme="red" ml={2}>
                          必須
                        </Badge>
                      ) : (
                        <Badge colorScheme="gray" ml={2}>
                          変更不可
                        </Badge>
                      )}
                    </FormLabel>
                    <Select
                      value={selectedTypePtn?.TypeName}
                      onChange={onChangeTypeName}
                    >
                      {isAddMode ? (
                        <>
                          <option value={""}>未選択</option>
                          {typePtns.map((typePtn) => (
                            <option
                              key={typePtn.TypeName}
                              value={typePtn.TypeName}
                            >
                              {typePtn.TypeName}
                            </option>
                          ))}
                        </>
                      ) : (
                        <option value={selectedTypePtn?.TypeName}>
                          {selectedTypePtn?.TypeName}
                        </option>
                      )}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel>セキュリティレベル</FormLabel>
                    <Select
                      value={securityLevel}
                      onChange={onChangeSecurityLevel}
                    >
                      <option value="">未設定</option>
                      <option value={SecurityLevelPtn.Public}>public</option>
                      <option value={SecurityLevelPtn.Private}>private</option>
                    </Select>
                  </FormControl>
                  {isProvider ? (
                    <>
                      <FormControl>
                        <FormLabel>EntityID</FormLabel>
                        <HStack>
                          <Input
                            type={"string"}
                            value={entityId}
                            placeholder="EntityID"
                            onChange={onChangeEntityId}
                          />
                          <PrimaryButton onClick={onClickEntityIdAdd}>
                            追加
                          </PrimaryButton>
                        </HStack>
                      </FormControl>
                      <Flex
                        border={"solid"}
                        borderWidth={1}
                        borderColor={"gray.300"}
                      >
                        <TableContainer>
                          <Table size={"sm"}>
                            <Thead>
                              <Tr>
                                <Th>EntityID</Th>
                                <Th textAlign={"center"}>削除</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {entityIds.map((cell, i) => (
                                <Tr key={i}>
                                  <Td>{cell}</Td>
                                  <Td textAlign={"center"}>
                                    <IconButton
                                      onClick={() => onClickIdListDelete(i)}
                                      variant="outline"
                                      colorScheme="red"
                                      aria-label="削除"
                                      size={"xs"}
                                      icon={<CloseIcon />}
                                    />
                                  </Td>
                                </Tr>
                              ))}
                            </Tbody>
                          </Table>
                        </TableContainer>
                      </Flex>
                      <Spacer />
                    </>
                  ) : (
                    <Spacer />
                  )}
                </Stack>
              </TabPanel>
              <TabPanel>
                <Stack>
                  <Flex fontSize={"sm"} m={1}>
                    アクセス許可するグループ
                  </Flex>
                  <Flex
                    border={"solid"}
                    borderWidth={1}
                    borderColor={"gray.300"}
                  >
                    <TableContainer>
                      <Table size={"sm"}>
                        <Thead>
                          <Tr>
                            <Th>市町村</Th>
                            <Th>グループ名</Th>
                            <Th>グループID</Th>
                            <Th>権限</Th>
                            <Th textAlign={"center"}>削除</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {permissions.map((cell, i) => (
                            <Tr key={i}>
                              <Td px={1} py={2}>
                                <Input
                                  width={"6rem"}
                                  size={"sm"}
                                  onChange={onChangeCell(i, "tenant_id")}
                                  value={cell.tenant_id}
                                />
                              </Td>
                              <Td px={1} py={2}>
                                <Select
                                  width={"12rem"}
                                  size={"sm"}
                                  value={cell.group_id}
                                  onChange={onChangeList(i, "group_id")}
                                >
                                  {cell.tenant_id === selectTenant &&
                                    tenantGroups.map((g) => (
                                      <option
                                        value={g.group_id}
                                      >{`${g.title}`}</option>
                                    ))}
                                  <option value={cell.group_id}>
                                    （ID直接入力）
                                  </option>
                                </Select>
                              </Td>
                              <Td px={1} py={2}>
                                <Input
                                  width={"6rem"}
                                  size={"sm"}
                                  onChange={onChangeCell(i, "group_id")}
                                  value={cell.group_id}
                                />
                              </Td>
                              <Td px={1} py={2}>
                                <Select
                                  width={"7rem"}
                                  size={"sm"}
                                  value={cell.permission}
                                  onChange={onChangeList(i, "permission")}
                                >
                                  <option value={PermissionPtn.non}>
                                    未設定
                                  </option>
                                  <option value={PermissionPtn.read_only}>
                                    参照のみ
                                  </option>
                                  <option value={PermissionPtn.read_write}>
                                    更新可能
                                  </option>
                                </Select>
                              </Td>
                              <Td textAlign={"center"}>
                                <IconButton
                                  onClick={() => onClickListDelete(i)}
                                  variant="outline"
                                  colorScheme="red"
                                  aria-label="削除"
                                  size={"xs"}
                                  icon={<CloseIcon />}
                                />
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Flex>
                  <Spacer />
                  <PrimaryButton onClick={onClickListAdd}>行追加</PrimaryButton>
                </Stack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <HStack>
            {isAddMode ? (
              <PrimaryButton
                disabled={selectedTypePtn == undefined}
                onClick={onClickCreate}
              >
                追加
              </PrimaryButton>
            ) : (
              <>
                <PrimaryButton onClick={onClickUpdate}>更新</PrimaryButton>
                <PrimaryButton onClick={onClickDelete}>削除</PrimaryButton>
              </>
            )}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
