import "./App.css";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import { memo, FC, ReactElement } from "react";
import { useLoginUser } from "./hooks/useLoginUser";
import { Page404 } from "./compornents/pages/Page404";
import { Login } from "./compornents/pages/login/Login";
import { Confirm } from "./compornents/pages/login/Confirm";
import { ForgotPassword } from "./compornents/pages/login/ForgotPassword";
import { Register } from "./compornents/pages/home/Register";
import { Home } from "./compornents/pages/home/Home";
import { Users } from "./compornents/pages/home/Users";
import { Groups } from "./compornents/pages/home/Groups";
import { Tenant } from "./compornents/pages/home/Tenant";
import { TenantOperations } from "./compornents/pages/home/TenantOperations";
import { DatalakeBuckets } from "./compornents/pages/home/DatalakeBuckets";
import { DatalakeTables } from "./compornents/pages/home/DatalakeTables";
import { OpensearchUploads } from "./compornents/pages/home/OpensearchUploads";
import { DatalakeCsvDownloads } from "./compornents/pages/home/DatalakeCsvDownloads";
import { CsvImportDefs } from "./compornents/pages/home/CsvImportDefs";
import { CsvImports } from "./compornents/pages/home/CsvImports";
import { OrionEntityOperations } from "./compornents/pages/home/OrionEntityOperations";
import { OrionTypes } from "./compornents/pages/home/OrionTypes";
import { OrionSubscriptions } from "./compornents/pages/home/OrionSubscriptions";
import { OrionRegistrations } from "./compornents/pages/home/OrionRegistrations";
import { MetricConvertCountEntityType } from "./compornents/pages/home/MetricConvertCountEntityType";
import { DomainApis } from "./compornents/pages/home/DomainApis";
import { OpenApiMetric } from "./compornents/pages/home/OpenApiMetric";
import { ApiKeys } from "./compornents/pages/home/ApiKeys";
import { UsagePlans } from "./compornents/pages/home/UsagePlans";
import { DevPortalCustomers } from "./compornents/pages/home/DevPortalCustomers";
import { EmailIdentities } from "./compornents/pages/home/EmailIdentities";
import { EmailTemplates } from "./compornents/pages/home/EmailTemplates";
import { AlertConfigs } from "./compornents/pages/home/AlertConfigs";
import { AlertHistories } from "./compornents/pages/home/AlertHistories";
import { Tenants } from "./compornents/pages/home/Tenants";
import { TenantSelecter } from "./compornents/pages/home/TenantSelecter";

type categoryInfo = {
  seq: number;
  caption: string;
};

const cognitoCategory: categoryInfo = { seq: 0, caption: "ユーザー・グループ" };
const asetCategory: categoryInfo = { seq: 1, caption: "センサー" };
const lakeCategory: categoryInfo = { seq: 2, caption: "データレイク" };
const fiwareCategory: categoryInfo = { seq: 3, caption: "FIWARE" };
const apiCategory: categoryInfo = { seq: 4, caption: "API" };
const messageCategory: categoryInfo = { seq: 5, caption: "メール通知" };
const tenantCategory: categoryInfo = { seq: 6, caption: "テナント" };

export const categorys: categoryInfo[] = [
  cognitoCategory,
  asetCategory,
  lakeCategory,
  fiwareCategory,
  apiCategory,
  messageCategory,
  tenantCategory,
];
export type menuInfo = {
  category?: categoryInfo;
  seq: number;
  path: string;
  caption: string;
  description: string;
  page: ReactElement;
  allow: { user: boolean; tenantAdmin: boolean; accountAdmin: boolean };
};
export const menuSet: menuInfo[] = [
  {
    category: cognitoCategory,
    seq: 1,
    path: "users",
    caption: "ユーザー管理",
    description: "ユーザーを追加・更新・削除します。",
    page: <Users />,
    allow: { user: false, tenantAdmin: true, accountAdmin: true },
  },
  {
    category: cognitoCategory,
    seq: 2,
    path: "groups",
    caption: "グループ管理",
    description: "グループを追加・更新・削除します。",
    page: <Groups />,
    allow: { user: false, tenantAdmin: true, accountAdmin: true },
  },
  {
    category: asetCategory,
    seq: 11,
    path: "tenant",
    caption: "センサー管理",
    description: "センサーを追加・更新・削除します。",
    page: <Tenant />,
    allow: { user: false, tenantAdmin: true, accountAdmin: true },
  },
  {
    category: asetCategory,
    seq: 12,
    path: "tenantope",
    caption: "センサー設定反映",
    description: "センサー管理の設定内容を反映します。",
    page: <TenantOperations />,
    allow: { user: false, tenantAdmin: true, accountAdmin: true },
  },
  {
    category: lakeCategory,
    seq: 21,
    path: "datalakebuckets",
    caption: "ストレージ統計情報",
    description: "データレイクのストレージ容量を表示します。",
    page: <DatalakeBuckets />,
    allow: { user: true, tenantAdmin: true, accountAdmin: true },
  },
  {
    category: lakeCategory,
    seq: 22,
    path: "datalaketables",
    caption: "データカタログ情報",
    description: "データレイクのデータカタログ・テーブル情報を表示します。",
    page: <DatalakeTables />,
    allow: { user: true, tenantAdmin: true, accountAdmin: true },
  },
  {
    category: lakeCategory,
    seq: 23,
    path: "opensearchUploads",
    caption: "CSV取込",
    description:
      "データレイクにCSVファイルをアップロードし、FIWARE等に取り込みます。",
    page: <OpensearchUploads />,
    allow: { user: false, tenantAdmin: true, accountAdmin: true },
  },
  {
    category: lakeCategory,
    seq: 24,
    path: "datalakeCsvDownloads",
    caption: "CSVダウンロード",
    description: "データレイクからCSVをダウンロードします。",
    page: <DatalakeCsvDownloads />,
    allow: { user: false, tenantAdmin: true, accountAdmin: true },
  },
  {
    category: lakeCategory,
    seq: 25,
    path: "csvImportDefs",
    caption: "CSV取込定義管理",
    description:
      "データレイクに取込するCSVファイルの定義を追加・更新・削除します。",
    page: <CsvImportDefs />,
    allow: { user: false, tenantAdmin: true, accountAdmin: true },
  },
  {
    category: lakeCategory,
    seq: 26,
    path: "csvImports",
    caption: "CSV取込（取込定義選択）",
    description:
      "CSV取込定義で設定したCSVファイルをデータレイクやFIWAREに取り込みます。",
    page: <CsvImports />,
    allow: { user: false, tenantAdmin: true, accountAdmin: true },
  },
  {
    category: fiwareCategory,
    seq: 31,
    path: "orionentityoperations",
    caption: "エンティティ操作",
    description:
      "FIWARE（Orion Context Broker）内のEntityに対して一括処理を行います。",
    page: <OrionEntityOperations />,
    allow: { user: false, tenantAdmin: true, accountAdmin: true },
  },
  {
    category: fiwareCategory,
    seq: 32,
    path: "entitytypes",
    caption: "エンティティタイプ情報",
    description:
      "FIWARE（Orion Context Broker）内のEntityの登録状況を表示します。",
    page: <OrionTypes />,
    allow: { user: false, tenantAdmin: true, accountAdmin: true },
  },
  {
    category: fiwareCategory,
    seq: 33,
    path: "subscriptions",
    caption: "サブスクリプション情報",
    description:
      "FIWARE（Orion Context Broker）内のSubscriptionの設定内容を表示します。",
    page: <OrionSubscriptions />,
    allow: { user: false, tenantAdmin: true, accountAdmin: true },
  },
  {
    category: fiwareCategory,
    seq: 34,
    path: "registrations",
    caption: "レジストレーション情報",
    description:
      "FIWARE（Orion Context Broker）内のRegistrationの設定内容を表示します。",
    page: <OrionRegistrations />,
    allow: { user: false, tenantAdmin: true, accountAdmin: true },
  },
  {
    category: fiwareCategory,
    seq: 35,
    path: "metric/convertcount/entitytype",
    caption: "エンティティタイプ登録状況",
    description: "FIWARE（Orion Context Broker）のエンティティタイプ別登録状況を表示します。",
    page: <MetricConvertCountEntityType />,
    allow: { user: false, tenantAdmin: true, accountAdmin: true },
  },
  {
    category: apiCategory,
    seq: 41,
    path: "domainapis",
    caption: "公開API情報",
    description: "公開APIの情報を表示します。",
    page: <DomainApis />,
    allow: { user: false, tenantAdmin: true, accountAdmin: true },
  },
  {
    category: apiCategory,
    seq: 42,
    path: "openapimetric",
    caption: "公開API統計情報",
    description: "APIの使用状況を表示します。",
    page: <OpenApiMetric />,
    allow: { user: false, tenantAdmin: true, accountAdmin: true },
  },
  {
    category: apiCategory,
    seq: 43,
    path: "apikeys",
    caption: "APIキー管理",
    description: "APIキーを追加・更新・削除します。",
    page: <ApiKeys />,
    allow: { user: false, tenantAdmin: true, accountAdmin: true },
  },
  {
    category: apiCategory,
    seq: 44,
    path: "usageplans",
    caption: "使用量プラン管理",
    description: "APIの使用量プランを追加・更新・削除します。",
    page: <UsagePlans />,
    allow: { user: false, tenantAdmin: true, accountAdmin: true },
  },
  {
    category: apiCategory,
    seq: 45,
    path: "devportalcustomers",
    caption: "開発者ポータル",
    description: "開発者ポータルのユーザにAPIの使用を許可します。",
    page: <DevPortalCustomers />,
    allow: { user: false, tenantAdmin: true, accountAdmin: true },
  },
  {
    category: messageCategory,
    seq: 51,
    path: "identities",
    caption: "送信元アドレス管理",
    description: "メール通知の送信元アドレスを追加・更新・削除します。",
    page: <EmailIdentities />,
    allow: { user: false, tenantAdmin: true, accountAdmin: true },
  },
  {
    category: messageCategory,
    seq: 52,
    path: "templates",
    caption: "テンプレート管理",
    description: "メール通知のテンプレートを追加・更新・削除します。",
    page: <EmailTemplates />,
    allow: { user: false, tenantAdmin: true, accountAdmin: true },
  },
  {
    category: messageCategory,
    seq: 53,
    path: "alertconfigs",
    caption: "監視メール設定",
    description: "データ受信状況を監視しメール通知します。",
    page: <AlertConfigs />,
    allow: { user: false, tenantAdmin: true, accountAdmin: true },
  },
  {
    category: messageCategory,
    seq: 54,
    path: "alerthistories",
    caption: "監視メール履歴",
    description: "センサーからのデータ送信状態を監視設定します。",
    page: <AlertHistories />,
    allow: { user: false, tenantAdmin: false, accountAdmin: false },  //画面未作成
  },
  {
    category: tenantCategory,
    seq: 61,
    path: "tenants",
    caption: "テナント管理",
    description: "テナントを更新・削除します。",
    page: <Tenants />,
    allow: { user: false, tenantAdmin: true, accountAdmin: true },
  },
  {
    category: tenantCategory,
    seq: 62,
    path: "regist",
    caption: "テナント追加",
    description: "テナントを追加します。",
    page: <Register />,
    allow: { user: false, tenantAdmin: false, accountAdmin: true },
  },
  {
    category: tenantCategory,
    seq: 63,
    path: "tenantselecter",
    caption: "テナント切替",
    description: "設定対象のテナントを変更します。",
    page: <TenantSelecter />,
    allow: { user: false, tenantAdmin: false, accountAdmin: true },
  },
];

export const MyRouter: FC = memo(() => {
  const { isLoggedIn, loginUser } = useLoginUser();

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={isLoggedIn ? <Navigate replace to="/home" /> : <Login />}
        />
        <Route
          path="/login"
          element={isLoggedIn ? <Navigate replace to="/home" /> : <Login />}
        />
        <Route
          path="/confirm/:id"
          element={isLoggedIn ? <Navigate replace to="/home" /> : <Confirm />}
        />
        <Route
          path="/forgotPassword"
          element={
            isLoggedIn ? <Navigate replace to="/home" /> : <ForgotPassword />
          }
        />
        <Route
          path="/home"
          element={isLoggedIn ? <Home /> : <Navigate replace to="/login" />}
        >
          {menuSet.map((menu) => {
            return (
              <Route
                key={menu.seq}
                path={menu.path}
                element={
                  loginUser ? (
                    menu.allow.accountAdmin && loginUser.isAccountAdmin ? (
                      menu.page
                    ) : menu.allow.tenantAdmin && loginUser.isAdmin ? (
                      menu.page
                    ) : menu.allow.user ? (
                      menu.page
                    ) : (
                      <Navigate replace to="/home" />
                    )
                  ) : (
                    <Navigate replace to="/home" />
                  )
                }
              />
            );
          })}
        </Route>
        <Route path="/regist" element={<Register />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Router>
  );
});
