import { useCallback, useState } from "react";
import axios from "axios";
import { GroupInfo, UsersInfo } from "@regional-data-interconnection/common";
import { useMessage } from "./useMessage";
import { useLoginUser } from "../hooks/useLoginUser";
import appconfig from "../config.json";

export const useGroup = () => {
  const { showMessage } = useMessage();
  const { loginUser, tokenRefresh, selectTenant } = useLoginUser();
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState<Array<GroupInfo>>([]);

  const GROUP_MANAGER_URL: string = appconfig["IOT_MANAGEMENT_API_URL"];

  const groupAdd = useCallback(
    (title: string, description: string, users: UsersInfo[]) => {
      setLoading(true);
      var group = {
        title: title,
        description: description,
        users: users,
      };
      new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
        let atr: string = "";
        if (selectTenant) {
          atr = "?tenant=" + selectTenant;
        }
        axios
          .post<any>(GROUP_MANAGER_URL + "/group" + atr, group, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(async (res) => {
            if (res.data) {
              console.log(res.data);
              showMessage({ title: "追加しました。", status: "success" });
              setLoading(false);
            } else {
              throw new Error(res.statusText);
            }
          })
          .then(() => {
            getGroups();
          })
          .catch((msg) => {
            showMessage({
              title: `グループが作成できません。(${msg})`,
              status: "error",
            });
            setLoading(false);
          });
      });
    },
    []
  );

  const groupEdit = useCallback(
    (
      group_id: string,
      title: string,
      description: string,
      users: UsersInfo[]
    ) => {
      setLoading(true);
      var group = {
        group_id: group_id,
        title: title,
        description: description,
        users: users,
      };
      new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
        let atr: string = "";
        if (selectTenant) {
          atr = "?tenant=" + selectTenant;
        }
        axios
          .put<any>(GROUP_MANAGER_URL + "/group" + atr, group, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(async (res) => {
            if (res.data) {
              console.log(res.data);
              showMessage({ title: "更新しました。", status: "success" });
              setLoading(false);
            } else {
              throw new Error(res.statusText);
            }
          })
          .then(() => {
            getGroups();
          })
          .catch((msg) => {
            showMessage({
              title: `グループが更新できません。(${msg})`,
              status: "error",
            });
            setLoading(false);
          });
      });
    },
    []
  );

  const groupDelete = useCallback((group_id: string) => {
    setLoading(true);
    new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
      let atr: string = "";
      if (selectTenant) {
        atr = "?tenant=" + selectTenant;
      }

      axios
        .delete<any>(GROUP_MANAGER_URL + "/group/" + group_id + atr, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(async (res) => {
          if (res.status == 204) {
            showMessage({ title: "削除しました。", status: "success" });
            setLoading(false);
          } else if (res.status == 403) {
            showMessage({ title: "権限エラー:" + res.data, status: "error" });
            setLoading(false);
          } else {
            showMessage({ title: res.statusText, status: "error" });
            setLoading(false);
          }
        })
        .then(() => {
          getGroups();
        })
        .catch((msg) => {
          showMessage({
            title: `グループが削除できません(${msg})`,
            status: "error",
          });
          setLoading(false);
        });
    });
  }, []);

  const getGroups = useCallback(() => {
    setLoading(true);
    new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
      let atr: string = "";
      if (selectTenant) {
        atr = "?tenant=" + selectTenant;
      }
      axios
        .get<Array<GroupInfo>>(GROUP_MANAGER_URL + "/groups" + atr, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) =>
          setGroups(
            res.data.sort((a, b) => {
              if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
              else return -1;
            })
          )
        )
        .catch(() =>
          showMessage({
            title: "グループの取得に失敗しました",
            status: "error",
          })
        )
        .finally(() => setLoading(false));
    });
  }, []);

  return { getGroups, groupAdd, groupEdit, groupDelete, loading, groups };
};
